<script setup>
const { locale, locales } = useI18n()
    // console.log(locale,locales)
    const switchLocalePath = useSwitchLocalePath()
    const {languages} = useConfig()

    const availableLocales = computed(() => {
    return (locales.value).filter(i => i.code !== locale.value)
    })
</script>
<template>
    <div class="language-switcher">
        <NuxtLink :class="{'active-language': locale == l}" v-for="l in locales" :key="l" :to="switchLocalePath(l)">{{
            l
        }}</NuxtLink>
    </div>
</template>
<style lang="scss">
    .language-switcher{
        // background-color: rgba($color: #fff, $alpha: .75);
        // border-radius:8px;
        padding:4px 6px;
        border-bottom:1px solid currentColor;
        // box-sizing: border-box;
        &::after{
            content:"";
            // background-color:$c-green-100;
            position: absolute;
        }
        a{
            font-weight: bold;
            text-transform: uppercase;
             position: relative;
            //  padding:4px 8px;
                font-weight: bolder;
               opacity: .5; 
               &:hover{
                opacity: 1;
               }
                 border-radius:6px;
            &:not(:last-child){
                
                  margin-right:.80rem;
               
                // &::after{
                //     content:'';
                //     position:absolute;
                //     pointer-events:none;
                //     // opacity: .5;
                //     font-weight: bolder;
                //     width:2px;
                //     height:100%;
                //     top:.1rem;
                //     transform: rotate(-25deg);
                //     background-color: currentColor;
                //     right:-.55rem;
                    
                // }

            }
            &.active-language{
                // font-weight: bold;
                pointer-events: none;
                // text-decoration: underline;
                opacity: 1;
                color:currentColor;
                color:#000;
                // background-color:$c-green-40;
                text-align: center;
                
                &::after{
                
                    content:"";
                    // display: inline;
                    position:absolute;
                    width:7px;
                    height:7px;
                    background-color: currentColor;
                    border-radius: 6px;
                    left:calc(50% - 3px);
                    bottom:-10px;
                    
                }
                // &::before{
                //     content:'';
                //     position:absolute;
                //     // width:calc(100% + 12px);
                //     // left:-6px;
                //     // top:-6px;
                //     // padding-top:calc(100% + 12px);
                //     // border-radius: 10px;
                //     // z-index: -1;
                //      background-color:currentColor;
                //     height:2px;
                //     bottom:-.25rem;
                //     width: 100%;
                // }
            }

        }

    }
    
</style>